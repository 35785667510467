import React from "react";
import moment from "moment";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
const Bomba = (props) => {
  const bomba = props.bomba.map((bomba) => {
    const diffTime = moment().diff(moment(bomba.data_hora), "minutes");
    return {
      id: bomba.id_ponto,
      nome: bomba.nome_ponto,
      acionamento:
        // eslint-disable-next-line
        bomba.status_acionar == 1 ? (
          <Badge variant="primary">Ligada</Badge>
        ) : (
          <Badge variant="info">Desligada</Badge>
        ),
      data: moment(bomba.data_hora).format("DD/MM/YYYY HH:mm:ss"),
      status: diffTime <= 30 ? "Online" : "Offline",
      statusBadge:
        diffTime <= 30 ? (
          <Badge variant="primary">Online</Badge>
        ) : (
          <Badge variant="info">Offline</Badge>
        ),
    };
  });
  return (
    <div>
      <Table responsive>
        <thead>
          <tr className="thead">
            <th>Nome do Ponto</th>
            <th>Situação do Acionamento</th>
            <th>Última Atualização</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {bomba.map((bomba) => {
            return (
              <tr className={bomba.status.toLowerCase()} key={bomba.id}>
                <td>{bomba.nome}</td>
                <td>{bomba.acionamento}</td>
                <td>{bomba.data}</td>
                <td>{bomba.statusBadge}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default Bomba;
