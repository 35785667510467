import React from "react";
import moment from "moment";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";

const Pressao = props => {
    const pressao = props.pressao.map(pressao => {
        const diffTime = moment().diff(moment(pressao.data_hora), "minutes");
        return {
            id: pressao.id_ponto,
            nome: pressao.nome_ponto,
            pressao: pressao.pressao,
            data: moment(pressao.data_hora).format("DD/MM/YYYY HH:mm:ss"),
            status: diffTime <= 30 ? "Online" : "Offline",
            statusBadge:
              diffTime <= 30 ? (
                <Badge variant="primary">Online</Badge>
            ) : (
                <Badge variant="info">Offline</Badge>
            ),
        };
    });
    return (
        <div>
            <Table responsive>
                <thead>
                    <tr className="thead">
                        <th>Nome do Ponto</th>
                        <th>Pressão (m.c.a.)</th>
                        <th>Última Atualização</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {pressao.map((pressao) => {
                        return (
                            <tr className={pressao.status.toLowerCase()} key={pressao.id}>
                                <td>{pressao.nome}</td>
                                <td>{pressao.pressao}</td>
                                <td>{pressao.data}</td>
                                <td>{pressao.statusBadge}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default Pressao;