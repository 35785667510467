import React from "react";
import moment from "moment";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
const Nivel = (props) => {
  const nivel = props.nivel.map((nivel) => {
    const diffTime = moment().diff(moment(nivel.data_hora), "minutes");
    return {
      id: nivel.id_ponto,
      nome: nivel.nome_ponto,
      nivel: nivel.nivel,
      data: moment(nivel.data_hora).format("DD/MM/YYYY HH:mm:ss"),
      status: diffTime <= 30 ? "Online" : "Offline",
      statusBadge:
        diffTime <= 30 ? (
          <Badge variant="primary">Online</Badge>
        ) : (
          <Badge variant="info">Offline</Badge>
        ),
    };
  });
  return (
    <div>
      <Table responsive>
        <thead>
          <tr className="thead">
            <th>Nome do Ponto</th>
            <th>Nível (m.c.a)</th>
            <th>Última Atualização</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {nivel.map((nivel) => {
            return (
              <tr className={nivel.status.toLowerCase()} key={nivel.id}>
                <td>{nivel.nome}</td>
                <td>{nivel.nivel}</td>
                <td>{nivel.data}</td>
                <td>{nivel.statusBadge}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default Nivel;
