import React from "react";
import moment from "moment";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
const Vazao = (props) => {
  const vazao = props.vazao.map((vazao) => {
    const diffTime = moment().diff(moment(vazao.data_hora), "minutes");
    return {
      id: vazao.id_ponto,
      nome: vazao.nome_ponto,
      vazao: vazao.vazao,
      volume: vazao.volume,
      nivel: vazao.nivel,
      data: moment(vazao.data_hora).format("DD/MM/YYYY HH:mm:ss"),
      status: diffTime <= 30 ? "Online" : "Offline",
      statusBadge:
        diffTime <= 30 ? (
          <Badge variant="primary">Online</Badge>
        ) : (
          <Badge variant="info">Offline</Badge>
        ),
    };
  });
  return (
    <div>
      <Table responsive>
        <thead>
          <tr className="thead">
            <th>Nome do Ponto</th>
            <th>Vazão (l/s)</th>
            <th>Volume (m³)</th>
            <th>Nível (m.c.a.)</th>
            <th>Última Atualização</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {vazao.map((vazao) => {
            return (
              <tr className={vazao.status.toLowerCase()} key={vazao.id}>
                <td>{vazao.nome}</td>
                <td>{vazao.vazao}</td>
                <td>{vazao.volume}</td>
                <td>{vazao.nivel}</td>
                <td>{vazao.data}</td>
                <td>{vazao.statusBadge}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default Vazao;
