import React from "react";
const Footer = () => {
  return (
    <footer>
      <div className="footer-widget-section ">
        <ul id="copyright">
          <li>
            &copy; SagaTech. Empresa do Grupo Saga. Todos os Direitos
            reservados. Design:{" "}
            <a href="https://www.sagatechbrasil.com.br">SagaTech</a>
          </li>
        </ul>
      </div>
    </footer>
  );
};
export default Footer;
